<template>
	<div>
		<DialogBox>
			<template v-slot:title>
				<h2>Historial</h2>
			</template>
			<template v-slot:subtitle>
				<div class="form">
					<DatePicker locale="es" :masks="masks" :model-config="modelConfig" v-model="dates" :max-date="new Date()" isRange> 
						<template v-slot="{ inputValue, inputEvents }">
							<div class="flex justify-center items-center">
								<label for="start" class="hide-mobile">Desde</label>
								<input
									:value="inputValue.start"
									v-on="inputEvents.start"
									class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
								/>
								<svg class="w-4 h-4 mx-2 just-mobile" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"/></svg>							
								<label for="end" class="hide-mobile">Hasta</label>
								<input
									:value="inputValue.end"
									v-on="inputEvents.end"
									class="border px-2 py-1 w-32 rounded focus:outline-none focus:border-indigo-300"
								/>
							</div>
						</template>
					</DatePicker>
					<button class="button" @click="getHistory(1)" :disabled="dates.start == null && dates.end == null">Consultar</button>
				</div>
			</template>
			<template v-slot:body>
				<template v-if="bets.length > 0">
					<div class="totals" :class="{corredor: user && user.corredor}">
						<span><b>Utilidad:</b> {{ utility | formatBalance }}</span>
						<span><b>Ventas:</b> {{ sells | formatBalance }}</span>
						<span v-if="user.corredor"><b>Comisión:</b>  {{ comisions | formatBalance }}</span>
					</div>
					<HistoryBets v-for="bet in bets" :key="bet.id" class="ticket" :bet="bet"/>
					<Pagination v-if="history" :info="history" @goPage="getHistory"/>
				</template>
				<div v-else class="totals">
					<p>No hay apuestas en esta selección</p>
				</div>
			</template>
		</DialogBox>
	</div>
</template>

<script>
	import DialogBox from '../components/DialogBox'
	import HistoryBets from '../components/HistoryBets'
	import Pagination from '../components/Pagination'
	import DatePicker from 'v-calendar/lib/components/date-picker.umd'

	export default {
		name: 'History',
		components: {
			DialogBox,
			DatePicker,
			HistoryBets,
			Pagination,
		},
		data() {
			return {
				dates: {
					start: null,
					end: null,
				},
				masks: {
					input: 'DD-MM-YYYY',
				},
				modelConfig: {
					type: 'string',
					mask: 'YYYY-MM-DD', // Uses 'iso' if missing
				},				
			}
		},
		computed: {
			history() {
				return this.$store.getters.history
			},
			bets() {
				return this.$store.getters.history ? this.$store.getters.history.data : []
			},
			utility() {
				return this.history ? this.history.profit : null
			},
			sells() {
				return this.history ? this.history.sells : null
			},
			comisions() {
				return this.history ? this.history.comision : null
			},
			user() {
				return this.$store.getters.user
			},
		},
		methods: {
			getHistory(page = 1) {
				let payload = {
					page: page,
					dates: this.dates
				}
				
				this.$store.dispatch('getHistory', payload)				
			},
			formatDate(date) {
				var d = new Date(date),
					month = '' + (d.getMonth() + 1),
					day = '' + d.getDate(),
					year = d.getFullYear();

				if (month.length < 2) 
					month = '0' + month;
				if (day.length < 2) 
					day = '0' + day;

				return [year, month, day].join('-');
			}			
		},
		mounted() {
			if (! this.user) {
				this.$router.push({ name: 'Home' })
			}
		},		
		created() {
			let today = new Date();
			this.dates = {start: this.formatDate(today), end: this.formatDate(today)}
				let payload = {
					page: 1,
					dates: this.dates
				}
				
				this.$store.dispatch('getHistory', payload)				
		}
	}
</script>