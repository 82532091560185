<template>
	<div class="pagination" v-if="info.last_page > 1">
		<ul>
			<li v-if="info.current_page > 1" @click="navigate(-1)">
				<a class="prev" href="#">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.024 4h6.015l7.961 8-7.961 8h-6.015l7.961-8-7.961-8zm-10.024 16h6.015l7.961-8-7.961-8h-6.015l7.961 8-7.961 8z"/></svg>	
				</a>
				</li>
			<li @click="goPage(index)" v-for="index in info.last_page" :key="index" :class="{active: info.current_page == index}">
				<a href="#">
					{{ index }}
				</a>
			</li>
			<li @click="navigate(1)" v-if="info.current_page < info.last_page">
				<a href="#">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M10.024 4h6.015l7.961 8-7.961 8h-6.015l7.961-8-7.961-8zm-10.024 16h6.015l7.961-8-7.961-8h-6.015l7.961 8-7.961 8z"/></svg>
				</a>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		name: 'Pagination',
		props: {
			info: {
				type: Object,
				required: true,
			}
		},
		methods: {
			goPage(page) {
				
				window.scroll({
					top: 0,
					left: 0,
					behavior: 'smooth'
				})
				this.$emit('goPage', page)	
			},
			navigate(add) {
				let page = this.info.current_page + add
				this.goPage(page)
			},
		}
	}
</script>

<style lang="scss" scoped>
	
	.pagination {
		margin: 0 10%;
	}
	
	ul {
		@include flex-container(center, center, center);
		margin: 20px 0;

		li {
			@include flex-container(center, center, center);
			@include effect(all, .3s, ease-out);
			cursor: pointer;
			padding: 10px 15px;			
			background-color: $orangeTheme;
			border: 1px $gray solid;

			a {
				@extend %bold;
				color: $white;
				font-size: 20px;

				svg {
					height: 18px;

					path {
						fill: $white;
					}
				}

				&.prev {

					svg {
						@include rotate(180);
					}
				}
			}

			&.active {
				background-color: $white;
				
				a {
					color: $orangeTheme;
				}
			}
		}
	}

	@include mobile {
		
		.pagination {
			margin: 0 4%;
		}
		
		ul {

			li {
				padding: 6px 9px;
				a {
					font-size: 14px;

					svg {
						height: 14px;
					}
				}
			}
		}
	}

	@include nomobile {

		ul {

			li {

				&:hover {
					background-color: $darkGray;
					
					a {
						color: $orangeTheme;

						svg {

							path {
								fill: $orangeTheme;
							}
						}
					}				
				}				
			}
		}
	}
</style>