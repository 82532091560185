<template>
	<div>
		<template v-if="!pending">			
			<svg v-if="item.games_probs.acierto" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>
			<svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z"/></svg>
		</template>
		<svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M18.513 7.119c.958-1.143 1.487-2.577 1.487-4.036v-3.083h-16v3.083c0 1.459.528 2.892 1.487 4.035l3.086 3.68c.567.677.571 1.625.009 2.306l-3.13 3.794c-.936 1.136-1.452 2.555-1.452 3.995v3.107h16v-3.107c0-1.44-.517-2.858-1.453-3.994l-3.13-3.794c-.562-.681-.558-1.629.009-2.306l3.087-3.68zm-4.639 7.257l3.13 3.794c.652.792.996 1.726.996 2.83h-1.061c-.793-2.017-4.939-5-4.939-5s-4.147 2.983-4.94 5h-1.06c0-1.104.343-2.039.996-2.829l3.129-3.793c1.167-1.414 1.159-3.459-.019-4.864l-3.086-3.681c-.66-.785-1.02-1.736-1.02-2.834h12c0 1.101-.363 2.05-1.02 2.834l-3.087 3.68c-1.177 1.405-1.185 3.451-.019 4.863z"/></svg>
		<span :class="hasResult">{{ item.games_probs.game.event }} | <b>{{ selection(item.games_probs) }}</b></span>
	</div>
</template>

<script>
	export default {
		name: 'HistoryBetDetail',
		props: {
			item: {
				type: Object,
				required: true,
			}
		},
		computed: {
			user() {
				return this.$store.getters.user
			},
			pending() {
				return this.item.games_probs.game.status == 0
			},		
		},
		methods: {
			selection(games_probs){
				if (! this.user) {
					return null
				}
				var cantidad = ''
				var salida = ''
				
				//Ganadores
				if(games_probs.tipo_probabilidad==1 || games_probs.tipo_probabilidad==8 || games_probs.tipo_probabilidad==11 || games_probs.tipo_probabilidad==15)
				{
					salida = games_probs.tipo_apuesta.nombre + ": " + games_probs.equipo.nombre + " (" + this.logro(games_probs.probabilidad, this.user.logro) + ")";
				}
				//Runlines
				else if (games_probs.tipo_probabilidad==3 || games_probs.tipo_probabilidad==7 || games_probs.tipo_probabilidad==12 || games_probs.tipo_probabilidad==16) {
					if (games_probs.cantidad == 0) {
						cantidad = "PK";
					}
					else
					{
						var signo = games_probs.games_probs_signo.signo;


						cantidad = signo + games_probs.cantidad;
					}

					salida = games_probs.tipo_apuesta.nombre + ": " + games_probs.equipo.nombre + " (" + cantidad + " " + this.logro(games_probs.probabilidad, this.user.logro) + ")";
				}
				//Totales
				else if(games_probs.tipo_probabilidad==4 || games_probs.tipo_probabilidad==10 || games_probs.tipo_probabilidad==13 || games_probs.tipo_probabilidad==17 || games_probs.tipo_probabilidad==18) {
					signo = (games_probs.orden == 1) ? "+" : "-";
					salida = games_probs.tipo_apuesta.nombre + " (" + signo + games_probs.cantidad + " " + this.logro(games_probs.probabilidad, this.user.logro) + ")";
				}
				else if(games_probs.tipo_probabilidad==6 || games_probs.tipo_probabilidad==14){
					salida = games_probs.tipo_apuesta.nombre + " (" + this.logro(games_probs.probabilidad, this.user.logro) + ")";
				}
				else if(games_probs.tipo_probabilidad==9) {
					salida = games_probs.tipo_apuesta.nombre + ": ";
					salida += (games_probs.orden == 1) ? "SI" : "NO";
					salida += " " + this.logro(games_probs.probabilidad, this.user.logro) + ")";

				}
				else if(games_probs.tipo_probabilidad == 19){
					salida = games_probs.equipo.nombre;
				}
				return salida;
			},
			logro(value, tipo){
				var mostrar = ''
				var americano = ''
				if (tipo == 1){
					if (value >= 2)
					{
						americano=(value-1)*100;
						mostrar = "+" + americano.toPrecision(3);
					}
					else
					{
					americano= -100/(value-1);
						if (value==0)
						{
							mostrar = '+' + americano.toPrecision(3);
						}
						else
						{
							mostrar = americano.toPrecision(3);
						}
					}
				}
				else{
					mostrar = value;
				}
				return mostrar;
			},
		}		
	}
</script>

<style lang="scss" scoped>

</style>