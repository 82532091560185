import { render, staticRenderFns } from "./HistoryBetDetail.vue?vue&type=template&id=78e2b7c6&scoped=true&"
import script from "./HistoryBetDetail.vue?vue&type=script&lang=js&"
export * from "./HistoryBetDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78e2b7c6",
  null
  
)

export default component.exports