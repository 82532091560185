<template>
	<div>
		<div class="ticket__title">
			<h2><span>Ticket</span> #{{ bet.id }}</h2>
			<h2><span>Fecha:</span> {{ bet.fecha_registro | dateParse('YYYY-MM-DD HH:mm:ss') | dateFormat('DD.MM.YYYY hh:mm a') }}</h2>
		</div>
		<div class="ticket__bets">
			<HistoryBetDetail v-for="item in bet.bets_detail" :key="item.id" :item="item" class="ticket__bets__item" />
		</div>
		<div class="ticket__footer">
			<h2><span>Monto Arriesgado:</span> {{ bet.exchanged_amount | formatBalance }}</h2>
			<h2><span>A Ganar:</span> {{ bet.exchanged_profit | formatBalance }}</h2>
		</div>
	</div>
</template>

<script>
	import HistoryBetDetail from './HistoryBetDetail'
	export default {
		name: 'HistoryBets',
		components: {
			HistoryBetDetail
		},
		props: {
			bet: {
				type: Object,
				required: true,
			}
		},
	}
</script>

<style lang="scss" scoped>

</style>